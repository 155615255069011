/* You can add global styles to this file, and also import other style files */
/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
